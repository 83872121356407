<div class="in-cart-container">
  <div *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity" class="added"></div>
  <div *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity" class="added-comment-box">
    <div class="added-comment">in cart</div>
  </div>
</div>
<div class="item-box"
  [ngClass]="{'in-cart-items':item.relatedItems&&item.relatedItems[0].temp_quantity, 'card_view_one-image-item-box':is_cardview, 'one-image-item-box':!is_cardview}">
  <div style="display: flex; justify-content: center;">
    <div class="item-image-box one-image-item-image-box">
      <div class="item-image"
        [ngClass]="{'card_view_one-image-item-image':is_cardview, 'one-image-item-image':!is_cardview}"
        (click)="openModalItem(item)">
        <ng-container *ngIf="item.is_single else imageNormal">
          <div style="position:relative;">
            <picture>
              <!-- <source type="image/webp" srcset="{{item.webpL}}" (click)="openModalItem(item)" /> -->
              <source type="image/jpeg" srcset="{{item.imageL}}" (click)="openModalItem(item)" />
              <img src="{{item.imageL}}" (click)="openModalItem(item)" />
            </picture>
            <div class="button-image-zoom_in"><span><mat-icon>zoom_in</mat-icon></span></div>
            <span *ngIf="item.sale_words" class="sale-words">{{item.sale_words}}</span>

            <div *ngIf="item.card_condition && item.card_condition.is_foiled"
              class="product-tag is_foiled one-image-is_foiled">
              FOIL
            </div>
            <div *ngIf="item.card_condition && item.card_condition.is_scratched"
              class="product-tag is_scratched one-image-is_scratched">
              キズ有り
            </div>
            <div *ngIf="is_purchase" class="product-tag one-image-purchase-tag">
              買取
            </div>
          </div>
        </ng-container>
        <ng-template #imageNormal>
          <div style="position:relative;">
            <picture>
              <source type="image/webp" srcset="{{item.webp}}" (click)="openModalItem(item)" />
              <source type="image/jpeg" srcset="{{item.image}}" (click)="openModalItem(item)" />
              <img src="{{item.image}}" (click)="openModalItem(item)" />
            </picture>
            <div class="button-image-zoom_in"><span><mat-icon>zoom_in</mat-icon></span></div>
            <span *ngIf="item.sale_words" class="sale-words">{{item.sale_words}}</span>

            <div *ngIf="item.card_condition && item.card_condition.is_foiled"
              class="product-tag is_foiled one-image-is_foiled">
              FOIL
            </div>
            <div *ngIf="item.card_condition && item.card_condition.is_scratched"
              class="product-tag is_scratched one-image-is_scratched">
              キズ有り
            </div>
            <div *ngIf="is_purchase" class="product-tag one-image-purchase-tag">
              買取
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div>
      <ng-container *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity">
        <div *ngIf="!is_purchase" matBadge="{{item.relatedItems[0].temp_quantity}}" matBadgeColor="accent"
          class="shopping-cart-button one-image-shopping-cart-button">
        </div>
        <div *ngIf="is_purchase" matBadge="{{item.relatedItems[0].temp_quantity}}" matBadgeColor="purchase"
          class="shopping-cart-button one-image-shopping-cart-button">
        </div>
      </ng-container>
      <div *ngIf="item.relatedItems&&item.relatedItems[0].is_sold_out&&!is_modal_open" class="sold_out-box"
        (click)="openModalItem(item)"
        [ngClass]="{'card_view_one-image-sold_out-box':is_cardview, 'one-image-sold_out-box':!is_cardview}">
        <div class="sold_out-label one-image-sold_out-label buying" *ngIf="is_purchase" else itemSoldOut>STOP</div>
        <ng-template #itemSoldOut>
          <div class="sold_out-label one-image-sold_out-label" *ngIf="!item.is_reserve_closed;" else itemClosed>SOLD OUT
          </div>
          <ng-template #itemClosed>
            <div class="sold_out-label one-image-sold_out-label">CLOSED</div>
          </ng-template>
        </ng-template>
      </div>
      <div *ngIf="item.relatedItems&&item.relatedItems[0].is_sold_out&&is_modal_open === true"
        class="sold_out-box one-image-sold_out-box"
        [ngClass]="{'card_view_one-image-sold_out-box':is_cardview, 'one-image-sold_out-box':!is_cardview}">
        <div class="sold_out-label one-image-sold_out-label buying" *ngIf="is_purchase" else itemSoldOut>STOP</div>
        <ng-template #itemSoldOut>
          <div class="sold_out-label one-image-sold_out-label" *ngIf="!item.is_reserve_closed;" else itemClosed>SOLD OUT
          </div>
          <ng-template #itemClosed>
            <div class="sold_out-label one-image-sold_out-label">CLOSED</div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
  <div [ngClass]="{'sold_out_item' : item.relatedItems&&item.relatedItems[0].is_sold_out}">
    <div class="one-image-item-container">
      <div [ngClass]="{'sold_out_item_text':item.relatedItems&&item.relatedItems[0].is_sold_out}">
        <div class="images-item-title one-image-item-title" *ngIf="!is_cardview">
          <a href="/{{localeId}}/{{path_str}}/{{item.game.code}}/cardViewer/{{item.id}}">
            {{item.name}} <span *ngIf="item.cardset && item.cardset.slip">[{{item.cardset.slip}}]</span></a>
        </div>
        <div class="images-item-title one-image-item-title" *ngIf="!is_cardview">
          {{item.fname}}
        </div>
      </div>

      <div class="row-item-buttons-box" [ngClass]="{'is_sold_out-price':item.relatedItems && item.relatedItems[0].is_sold_out && !is_reservation
                 ,'has-cart-item' : item.relatedItems&&item.relatedItems[0].quantity>0}">
        <div [ngClass]="{'sold_out_item_text':item.relatedItems&&item.relatedItems[0].is_sold_out}">
          <span *ngIf="is_purchase">
            WEB限定買取価格
          </span>
          <div>
            <span *ngIf="item.language && item.language.web && item.language.web != '指定なし'">
              [{{item.language.web}}]
            </span>
            <ng-container *ngIf="item.rarity" class="item-rarity-box">
              <span *ngIf="item.rarity.slip && item.rarity.slip != ''; else rarityName">[{{item.rarity.slip}}]</span>
              <ng-template #rarityName><span *ngIf="item.rarity.web != ''">[{{item.rarity.web}}]</span></ng-template>
            </ng-container>
            <span *ngIf="!is_purchase">
              <ng-container *ngIf="item.card_condition && item.card_condition.web">
                {{item.card_condition.web}}
              </ng-container>
            </span>
          </div>
          <ng-container *ngIf="!is_purchase">
            <div class="viewbox_stock_exposition" [ngClass]="{'card_view_exposition':is_cardview}"
              *ngIf="item.exposition || item.comment">
              <div *ngIf="item.exposition" [innerHTML]="item.exposition"></div>
              <div class="comment_box" *ngIf="item.comment" [innerHTML]="item.comment">
                s </div>
            </div>
          </ng-container>
          <div>
            <div *ngIf="item.relatedItems&&!item.relatedItems[0].is_hidden_price&&item.price>0">
              <span class="item-point">
                <ng-container *ngIf="item.point>0">
                  {{item.point}}ポイント
                </ng-container>
              </span>
            </div>
          </div>
          <div class="one-image-price-box" *ngIf="!item.is_hidden_price&&item.price>0"
            [ngClass]="{'is_scratched-price':item.card_condition && item.card_condition.is_scratched}">
            <span *ngIf="item.is_bargain" class="base-price one-image-base-price">
              {{item.sale_prices|number}}円
            </span>
            <div class="item-price one-image-item-price"
              [ngClass]="{'item-product-price':!is_purchase,'item-purchase-price':is_purchase}">
              {{item.price|number}}円
            </div>
          </div>
        </div>
        <div class="item-buttons">
          <div *ngIf="item.relatedItems&&!item.relatedItems[0].is_sold_out">
            <div class="one-image-cart-gadget" [ngClass]="{'largeQuantity' : item.stock_count > 99}">
              <button class="cart-button" (click)="addCount(item,-1)">
                <i class="material-icons">remove</i>
              </button>
              <mat-select class="cart-input" [value]="item.relatedItems[0].quantity"
                [ngClass]="{'hasTempCart' : item.relatedItems&&item.relatedItems[0].quantity!=item.relatedItems[0].temp_quantity,'hasCart':item.relatedItems&&item.relatedItems[0].quantity>0}"
                (selectionChange)="selectionChange($event,item)" *ngIf="item.relatedItems[0].quantity<11">
                <mat-option *ngFor="let num of getStockOptions(item.stock_count)" [value]="num">
                  {{num}}
                </mat-option>
                <mat-option [value]="11" *ngIf="item.stock_count >10">
                  -- 直接入力 --
                </mat-option>
              </mat-select>
              <button class="cart-button" (click)="addCount(item,1)">
                <i class="material-icons">add</i>
              </button>
            </div>
            <div style="display: flex; width: 80%;">
              <input matInput type="number" class="cart-input" [value]="item.relatedItems[0].quantity"
                [ngClass]="{'hasTempCart' : item.relatedItems&&item.relatedItems[0].quantity!=item.relatedItems[0].temp_quantity,'hasCart':item.relatedItems&&item.relatedItems[0].quantity>0}"
                (change)="checkStock($event,item)">
              <span matSuffix class="cart-input-suffix">/{{item.relatedItems[0].stock_count}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!is_purchase">
          <div class="cart-item-buttons" *ngIf="!getBadgeCount(item)"
            [ngClass]="{'maxcount-button':item.relatedItems[0].temp_quantity==item.relatedItems[0].stock_count}">
            <div *ngIf="item.relatedItems&&!item.relatedItems[0].is_sold_out">
              <button class="add-to-cart-button-temp" (click)="addToCartByNow(item)"
                *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity <item.relatedItems[0].stock_count">
                <span class="plus-one-cart-button-label">カートに追加</span>
              </button>
              <div class="maxcount-message"
                *ngIf="!item.is_sold_out&&item.relatedItems&&item.relatedItems[0].temp_quantity ==item.relatedItems[0].stock_count">
                在庫上限です
              </div>
            </div>
          </div>
          <div *ngIf="getBadgeCount(item)">
            <div>
              <button *ngIf="item.relatedItems[0].temp_quantity" class="add-to-cart-button-temp"
                (click)="addToCart(item)" color="warn">
                <span class="add-to-cart-button-label">数量を変更</span>
              </button>
              <button *ngIf="!item.relatedItems[0].temp_quantity" class="add-to-cart-button-temp"
                (click)="addToCart(item)" color="warn">
                <span class="add-to-cart-button-label">カートに入れる</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="is_purchase">
          <div class="cart-item-buttons" *ngIf="!getBadgeCount(item)">
            <div *ngIf="!item.relatedItems[0].is_sold_out">
              <button class="add-to-cart-button-temp" (click)="addToPurchaseCartByNow(item)"
                *ngIf="item.relatedItems[0].temp_quantity <item.relatedItems[0].stock_count">
                <span class="plus-one-cart-button-label">買取カートに追加</span>
              </button>
              <div class="maxcount-message"
                *ngIf="item.relatedItems[0].temp_quantity ==item.relatedItems[0].stock_count">
                買取上限です
              </div>
            </div>
          </div>
          <div *ngIf="getBadgeCount(item)">
            <div>
              <button *ngIf="item.relatedItems[0].temp_quantity" class="add-to-cart-button-temp"
                (click)="addToPurchaseCart(item)" color="warn">
                <span class="add-to-cart-button-label">数量を変更</span>
              </button>
              <button *ngIf="!item.relatedItems[0].temp_quantity" class="add-to-cart-button-temp"
                (click)="addToPurchaseCart(item)" color="warn">
                <span class="add-to-cart-button-label">買取カートに入れる</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="item.relatedItems&&item.relatedItems[0].is_sold_out" class="one-image-sold_out">
          <div *ngIf="path_str!=='purchase'; else elseContent">
            <div class="one-image-sold_out_msg">売り切れ</div>
            <div>
              <button (click)="searchSameProducts(item)" mat-flat-button class=" mat-primary">
                <i class="material-icons">search</i><span i18n>同名検索</span>
              </button>
            </div>
          </div>
          <ng-template #elseContent>
            <div #elseContent>
              <div class="one-image-sold_out_msg">買取終了</div>
              <div>
                <button (click)="searchSameProducts(item)" mat-flat-button class=" mat-primary">
                  <i class="material-icons">search</i><span i18n>同名検索</span>
                </button>
              </div>
            </div>
          </ng-template>
        </div>

      </div>
    </div>
  </div>
</div>